import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'  // Optional: smooth scroll
    });
  };


  return (
    <div className="footer-container">

      {/* Contact Information */}
      <div className="contact-section">
      
        <p className="contact-details">
          <b>Mobile:</b>
           +91 9702260296<br />
        <b> Email: </b> contact@padoshiassociates.com <br />
         <b>Primary Address:</b> 1302, Ashtha Heights, Jawahar Nagar, <br />
          Goregaon West, Mumbai – 400104<br />
       <b>Additional Address:</b>    923, Ijmima Complex, <br/>
          Mindspace, Malad West, Mumbai – 400064
        </p>
      </div>

      {/* Quick Links */}
      <div className="quick-links-section">
        <h6 className="quick-links-title"><b>Quick Links:</b></h6>
        <ul className="quick-links-list">
          <li><a href="/" onClick={scrollToTop} >Home</a></li>
          <li><Link to="/about" onClick={scrollToTop}>About Us</Link></li>
          <li><Link to="/Service" onClick={scrollToTop}>Services</Link></li>
          <li>
          <Link to="/query" onClick={scrollToTop}>Query</Link>
        </li>
        
          <li><Link to="/Contact" onClick={scrollToTop}>Contact Us</Link></li>
        </ul>
      </div>

      {/* Services */}
      <div className="services-section">
        
        <h6 className="services-title"><b>Services</b>:</h6>
        <ul className="services-list">
        <li><Link to="/service/accounting-auditing" onClick={scrollToTop}>Accounting and Auditing</Link></li>
          <li><Link to="/service/income" onClick={scrollToTop}>Direct Tax</Link></li>
          <li><Link to="/service/indirect" onClick={scrollToTop}>Indirect Tax</Link></li>
          <li><Link to="/service/other" onClick={scrollToTop}>Other Services</Link></li>
          
        </ul>
      </div>
    </div>
  );
};

export default Footer;
